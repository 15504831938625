'use client';

import { useCallback, useEffect, useState } from 'react';
import {
  setAddressList,
  setMiniProfile,
  setSelectedAddressId,
  updateCachedProfileOnGuestAccountTransfer,
} from 'redux/features/customer';
import { createCookieConfig, getCookieInClient } from 'utils/cookie';
import {
  getAddressList,
  getNearByZoneIdByCordinate,
  saveAddressData,
  writeZoneIdToCookie,
} from 'lib/googlemap';
import { IAddressData } from 'types/shipping-method-switcher';
import { miniProfileFeeder } from 'lib/nahdi-middleware/account/customer-feeders';

import { AddressCache } from './shipping-method-switcher/maps/utils';
import { CustomerMiniProfile } from 'types/customer';
import { extractCustomerInfoFromLocalStorage } from './shipping-method-switcher/maps/utils/address.util';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { gtmSetLogin, gtmSetSignUp } from 'lib/gtm';
import { getFromLocalStorage } from 'utils/local-storage';
import { LanguageCodesType, RegionCodesType } from 'utils';
import {
  COOKIE_CUSTOMER_TOKEN,
  COOKIE_DY_ID_SERVER,
  COOKIE_MAGENTO_CUSTOMER_ID,
} from 'utils/constant-cookies';

export default function CustomerReduxFeeder({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();
  const [eventTrigger, setEventTrigger] = useState(0);
  const [cookies, setCookie] = useCookies([
    COOKIE_DY_ID_SERVER,
    COOKIE_MAGENTO_CUSTOMER_ID,
  ]);
  const getNearByZone = useCallback(
    async function (lng: number, lat: number) {
      return await getNearByZoneIdByCordinate(lng, lat, language, region);
    },
    [language, region]
  );

  const mode = useAppSelector(
    (state) => state.customerReducer.deliveryMode.mode
  );

  const addAddressToStore = useCallback(
    (addressList: IAddressData[], address: IAddressData) => {
      addressList.forEach((data) => {
        data.default_shipping = false;
        data.default_billing = false;
      });
      const newAddressIndex = addressList.findIndex(
        (add) => add.id == address.id
      );

      let list = addressList;
      if (newAddressIndex == -1) {
        list = [address, ...list];
      } else {
        list[newAddressIndex] = {
          ...list[newAddressIndex]!,
          default_shipping: true,
          default_billing: true,
        };
      }
      dispatch(setAddressList(list));
    },
    [dispatch]
  );

  const transferGuestData = useCallback(
    async function (data: IAddressData) {
      try {
        const [saveResp] = await Promise.all([
          saveAddressData<IAddressData>(
            {
              ...data,
              additional: undefined,
            },
            language,
            region
          ),
          getNearByZone(data.longitude, data.latitude),
        ]);
        const updatedList = await getAddressList<IAddressData[]>(
          language,
          region
        );
        addAddressToStore(updatedList ?? [], saveResp!);
      } catch (ex) {
        ex;
      }
    },
    [language, region, getNearByZone, addAddressToStore]
  );
  const customerToken = getCookieInClient(COOKIE_CUSTOMER_TOKEN);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const miniProfileFromLocaleStorage = getFromLocalStorage({
      key: 'miniProfile',
    });
    if (customerToken && !miniProfileFromLocaleStorage) {
      miniProfileFeeder({ language, region }).then(
        (miniProfile: CustomerMiniProfile) => {
          if (miniProfile) {
            setCookie(
              COOKIE_MAGENTO_CUSTOMER_ID,
              miniProfile.magento_customer_id,
              createCookieConfig({ expires: '' })
            );
            if (miniProfile.dy_server_id) {
              setCookie(
                COOKIE_DY_ID_SERVER,
                miniProfile.dy_server_id,
                createCookieConfig({ expires: '' })
              );
            } else if (cookies._dyid_server) {
              try {
                fetch(
                  `/api/auth/customer/mini-profile?language=${language}&region=${region}`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      firstname: miniProfile.firstname,
                      lastname: miniProfile.lastname,
                      email: miniProfile.email,
                      custom_attributes: [
                        {
                          attribute_code: 'cookie_dy_id',
                          value: cookies._dyid_server?.toString(),
                        },
                        {
                          attribute_code: 'cookie_dy_id_server',
                          value: cookies._dyid_server?.toString(),
                        },
                        {
                          attribute_code: 'nuhdeek_member_number',
                          value: miniProfile.nuhdeek_member_id?.toString(),
                        },
                        {
                          attribute_code: 'preferred_language',
                          value: miniProfile.preferred_language,
                        },
                      ],
                    }),
                  }
                );
              } catch (e) {
                // do nothing
              }
            }
            dispatch(setMiniProfile(miniProfile));
            const guestAddress = AddressCache.getAddressList(true);
            if (guestAddress.length > 0 && guestAddress?.[0]) {
              // save the guest user innformation
              const address: IAddressData = {
                ...guestAddress[0],
                firstname: miniProfile.firstname,
                lastname: miniProfile.lastname,
                default_shipping: true,
                default_billing: true,
                street: guestAddress[0].additional?.formatted_address
                  ? [guestAddress[0].additional?.formatted_address]
                  : guestAddress[0].street,
                telephone: extractCustomerInfoFromLocalStorage()?.phone ?? '',
              };
              const updated: CustomerMiniProfile = {
                ...miniProfile,
                default_address: address as never,
              };
              dispatch(setMiniProfile(updated));
              transferGuestData(address)
                .then(() => {
                  localStorage.removeItem('guest_address_key');
                  dispatch(updateCachedProfileOnGuestAccountTransfer());
                })
                .catch();
            }
            const isLogin = localStorage.getItem('gtm-login');
            const isRegister = localStorage.getItem('gtm-register');
            if (isLogin) {
              gtmSetLogin(
                'Login Success',
                miniProfile.magento_customer_id || '',
                miniProfile.email,
                miniProfile.mobile_number,
                miniProfile.firstname,
                miniProfile.lastname
              );
              localStorage.removeItem('gtm-login');
            }
            if (isRegister) {
              gtmSetSignUp(
                'Signup Success',
                miniProfile.email,
                miniProfile.mobile_number,
                miniProfile.magento_customer_id || '',
                miniProfile.firstname,
                miniProfile.lastname
              );
              localStorage.removeItem('gtm-register');
            }
          }
        }
      );

      const guestAddress = AddressCache.getAddressList(true);

      if (guestAddress.length < 1) {
        getAddressList<IAddressData[]>(language, region).then((data) => {
          if (data && data.length > 0) {
            const defaultAddress = data.findIndex(
              (add) => add.default_shipping
            );
            if (
              defaultAddress != -1 &&
              data[defaultAddress]?.latitude &&
              data[defaultAddress]?.longitude
            ) {
              if (data[defaultAddress]?.zones) {
                writeZoneIdToCookie(data[defaultAddress]?.zones!);
              } else {
                getNearByZone(
                  data[defaultAddress]?.longitude ?? 0,
                  data[defaultAddress]?.latitude ?? 0
                );
              }

              if (mode === 'delivery') {
                const deliveryAddressId =
                  AddressCache.getDeliveryAddressId() ||
                  data[defaultAddress]?.id?.toString();
                dispatch(setSelectedAddressId(deliveryAddressId));
              }
            } else {
              data[0]!.default_shipping = true;
              if (data[0]?.zones) {
                writeZoneIdToCookie(data[0]?.zones!);
              } else {
                getNearByZone(data[0]?.longitude ?? 0, data[0]?.latitude ?? 0);
              }
              if (mode === 'delivery') {
                dispatch(setSelectedAddressId(data[0]?.id?.toString()));
              }
            }

            dispatch(setAddressList(data));
          }
        });
      }
    } else {
      if (miniProfileFromLocaleStorage) {
        dispatch(setMiniProfile(miniProfileFromLocaleStorage));
        const guestAddress = AddressCache.getAddressList(true);

        if (guestAddress.length < 1) {
          getAddressList<IAddressData[]>(language, region).then((data) => {
            if (data && data.length > 0) {
              const defaultAddress = data.findIndex(
                (add) => add.default_shipping
              );
              if (
                defaultAddress != -1 &&
                data[defaultAddress]?.latitude &&
                data[defaultAddress]?.longitude
              ) {
                if (data[defaultAddress]?.zones) {
                  writeZoneIdToCookie(data[defaultAddress]?.zones!);
                } else {
                  getNearByZone(
                    data[defaultAddress]?.longitude ?? 0,
                    data[defaultAddress]?.latitude ?? 0
                  );
                }

                if (mode === 'delivery') {
                  const deliveryAddressId =
                    AddressCache.getDeliveryAddressId() ||
                    data[defaultAddress]?.id?.toString();
                  dispatch(setSelectedAddressId(deliveryAddressId));
                }
              } else {
                data[0]!.default_shipping = true;
                if (data[0]?.zones) {
                  writeZoneIdToCookie(data[0]?.zones!);
                } else {
                  getNearByZone(
                    data[0]?.longitude ?? 0,
                    data[0]?.latitude ?? 0
                  );
                }
                if (mode === 'delivery') {
                  dispatch(setSelectedAddressId(data[0]?.id?.toString()));
                }
              }

              dispatch(setAddressList(data));
            }
          });
        }
      }
      if (!customerToken) {
        // Clear local storage if user is not logged in (expired token or no token)
        dispatch(setMiniProfile(null));
        localStorage.removeItem('miniProfile');
        dispatch(setAddressList([]));
        dispatch(setSelectedAddressId());
      }
    }
  }, [dispatch, transferGuestData, eventTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  typeof document != 'undefined' &&
    document?.addEventListener(
      'customerFeederTrigger',
      () => setEventTrigger(eventTrigger + 1),
      false
    );

  return null;
}
