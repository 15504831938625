import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppSettings } from 'types/cms/gss';

export interface AppSettingsState {
  appSettings: AppSettings;
}

const initialState: AppSettingsState = {
  appSettings: {
    global_components: {
      basket: {
        max_distinct_sku: 0,
        max_qty_per_sku: 0,
      },
      header: {
        header_sticky: false,
        asset_type: 'IMAGE',
        background_color: '',
        mobile_background_video: '',
        web_background_video: '',
        web_background_image: '',
        mobile_background_image: '',
        search_placeholder: '',
        multiple_search_placeholder: '',
        label_text_color: '',
        search_as_you_type: false,
        desktop_logo: '',
        mobile_logo: '',
      },
      login: {
        otp_resend_time: 0,
        password_validation: [
          {
            messages: {
              ar: '',
              en: '',
            },
            regex: '',
          },
        ],
      },
      search: {
        instant_search: 'on',
      },
      range_values: [],
      store_locator: {
        services: [{ key: '1', value: { en: '1', ar: '1' } }],
      },
      is_referral_enabled: false,
    },
    functionalityConfig: {
      bazaarVoiceSettings: {
        DEV: {
          enabled: true,
        },
        PRODUCTION: {
          enabled: true,
        },
        STAGING: {
          enabled: true,
        },
      },
      nuhdeekSettings: {
        DEV: {
          enabled: true,
        },
        PRODUCTION: {
          enabled: true,
        },
        STAGING: {
          enabled: true,
        },
      },

      dynamicYieldSettings: {
        DEV: {
          enabled: true,
          registered_only: true,
        },
        PRODUCTION: {
          enabled: true,

          registered_only: true,
        },
        STAGING: {
          enabled: true,
          registered_only: true,
        },
      },
      features: {
        is_referral_enabled: false,
        e_pharmacist_base_link: '',
      },
      gtmSettings: {
        DEV: {
          id: '',
          enabled: false,
        },
        STAGING: {
          id: '',
          enabled: false,
        },
        PRODUCTION: {
          id: '',
          enabled: false,
        },
      },
      algoliaSettings: {
        indexes: {
          AE: {
            ar: '',
            en: '',
          },
          SA: {
            ar: '',
            en: '',
          },
        },
      },
    },
    downloadAppConfig: {
      rating: '',
      component_type: '',
      content: [],
    },
    name: '',
    version: 1,
  },
};

export const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    setAppSettings: (
      state: AppSettingsState,
      action: PayloadAction<AppSettings>
    ) => {
      state.appSettings = action.payload;
    },
  },
});

export const { setAppSettings } = cmsSlice.actions;
export default cmsSlice.reducer;
